/**
 * Is the current browser a GDPR scanner?
 *
 * @see https://regex101.com/r/H8RW1y/1
 * @see https://regex101.com/r/WYFXVU/1
 */ function isGdprScanner() {
    const { userAgent } = navigator;
    const { cookie } = document;
    if (userAgent) {
        if (/(cookiebot|2gdpr)\.com/i.test(userAgent)) {
            return true;
        }
        if (/cmpcrawler(reject)?cookie=/i.test(cookie)) {
            return true;
        }
    }
    return false;
}
export { isGdprScanner };
