import { ERouteHttpVerb } from "@devowl-wp/api";
/**
 * Use
 *
 * ```ts
 * import { ERouteHttpVerb } from "@devowl-wp/api";
 * ```
 *
 * instead!
 *
 * @deprecated For backwards-compatibility.
 */ const RouteHttpVerb = ERouteHttpVerb;
export { RouteHttpVerb };
