import { getOtherOptionsFromWindow } from "../utils";
/**
 * Apply Consent Forwarding, it set's cross domain cookies via CORS requests.
 *
 * @see https://javascript.info/fetch-crossorigin
 */ function applyConsentForwarding(param) {
    let { endpoints, data } = param;
    const { isPro } = getOtherOptionsFromWindow();
    if (isPro) {
        /* onlypro:start */ const promises = [];
        for (const endpoint of endpoints){
            promises.push(window.fetch(endpoint, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                },
                body: JSON.stringify(data)
            }));
        }
        return Promise.all(promises);
    /* onlypro:start */ } else {
        return Promise.reject();
    }
}
export { applyConsentForwarding };
