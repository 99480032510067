import { OPT_IN_EVENT, OPT_OUT_EVENT } from "..";
import { consentSync } from ".";
/**
 * `window.consentApi.consent`: Check if a given technical information (e.g. HTTP Cookie, LocalStorage, ...) has a consent:
 *
 * - When a technical information exists in defined cookies, the Promise is only resolved after given consent
 * - When no technical information exists, the Promise is immediate resolved
 *
 * Example (ES5):
 * ```js
 * (window.consentApi && window.consentApi.consent("http", "_twitter_sess", ".twitter.com") || Promise.resolve()).then(function() {
 *     console.log("Consent for Twitter embed given, do something...!");
 * });
 * ```
 *
 * Example (ES6, TS):
 * ```js
 * (window.consentApi?.consent("http", "_twitter_sess", ".twitter.com") || Promise.resolve()).then(() => {
 *     console.log("Consent for Twitter embed given, do something...!");
 * });
 * ```
 *
 * **Since 2.3.0**: You can also check for consent by cookie ID (ID in `wp_posts`, post id):
 *
 * ```js
 * window.consentApi.consent(15)
 * ```
 *
 * **Since 3.4.14**: You can also check for consent by service unique name:
 *
 * ```js
 * window.consentApi.consent("google-analytics-ua")
 * ```
 */ function consent() {
    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
        args[_key] = arguments[_key];
    }
    return new Promise((resolve, reject)=>{
        const { cookie: foundCookie, consentGiven, cookieOptIn } = consentSync(...args);
        if (foundCookie) {
            if (consentGiven) {
                if (cookieOptIn) {
                    resolve();
                } else {
                    reject();
                }
            } else {
                // Wait for consent
                document.addEventListener(OPT_IN_EVENT, async (param)=>{
                    let { detail: { service } } = param;
                    if (service === foundCookie) {
                        resolve();
                    }
                });
                // Wait for opt out
                document.addEventListener(OPT_OUT_EVENT, async (param)=>{
                    let { detail: { service } } = param;
                    if (service === foundCookie) {
                        reject();
                    }
                });
            }
        } else {
            resolve();
        }
    });
}
export { consent };
