let initializedAjaxSend = false;
/**
 * Opens the dialog to login again in WordPress.
 *
 * @see https://github.com/WordPress/gutenberg/issues/13509#issuecomment-1563964440
 */ function waitForValidLogin() {
    var _window_wp;
    const $ = window.jQuery;
    if (!((_window_wp = window.wp) == null ? void 0 : _window_wp.heartbeat) || !$) {
        return new Promise(()=>{
        // We will never get a valid login, should we forver show the "loading" state so the user starts to refresh the page?
        });
    }
    // Trigger the WordPress auth dialog to be shown
    // Unfortunately, wp-auth-check.js does not allow to trigger this via another API like `wp.auth.showLoginDialog()`
    // See https://github.com/WordPress/WordPress/blob/d4c39df30839133f3b9a46b01a229233d8f99f10/wp-includes/js/wp-auth-check.js#L162
    $(document).trigger("heartbeat-tick", [
        {
            "wp-auth-check": false
        },
        "error",
        null
    ]);
    // Suspend all heartbeat requests as they are not needed atm
    // See https://github.com/WordPress/WordPress/blob/d4c39df30839133f3b9a46b01a229233d8f99f10/wp-includes/js/heartbeat.js#L226-L228
    // $(window).trigger("unload.wp-heartbeat");
    // Unfortunately, we cannot use this event as when the user focus the window again, it requests the admin-ajax.php again
    if (!initializedAjaxSend) {
        initializedAjaxSend = true;
        $(document).ajaxSend((event, jqXHR, param)=>{
            let { url, data } = param;
            if ((url == null ? void 0 : url.endsWith("/admin-ajax.php")) && (data == null ? void 0 : data.indexOf("action=heartbeat")) > -1 && $("#wp-auth-check:visible").length > 0) {
                jqXHR.abort();
            }
        });
    }
    // Unfortuantely, wp-auth-check.js does not trigger events when auth dialog gets hidden
    // See https://github.com/WordPress/WordPress/blob/d4c39df30839133f3b9a46b01a229233d8f99f10/wp-includes/js/wp-auth-check.js#L110
    return new Promise((resolve)=>{
        const checkInterval = setInterval(()=>{
            if ($("#wp-auth-check:visible").length === 0) {
                clearInterval(checkInterval);
                resolve();
            }
        }, 100);
    });
}
export { waitForValidLogin };
