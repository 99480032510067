import { matchingSelector } from "@devowl-wp/resolve-none-computed-style";
import { fastdom } from "@devowl-wp/web-scoped-css";
window.rcbPoweredByCacheOuterHTML = "";
/**
 * Get the server-side-rendered powered by link.
 */ function getSsrPoweredByLink(id) {
    const originalPoweredLink = document.getElementById(id);
    const poweredLink = document.createElement("div");
    if (window.rcbPoweredByCacheOuterHTML) {
        // We have cached the link as we remove it from the DOM
        poweredLink.innerHTML = window.rcbPoweredByCacheOuterHTML;
    } else if (matchingSelector(originalPoweredLink, "a") && originalPoweredLink.innerHTML.toLowerCase().indexOf("Real Cookie Banner")) {
        window.rcbPoweredByCacheOuterHTML = originalPoweredLink.outerHTML;
        poweredLink.innerHTML = window.rcbPoweredByCacheOuterHTML;
        fastdom.mutate(()=>originalPoweredLink.parentNode.removeChild(originalPoweredLink));
    }
    return poweredLink.children[0];
}
export { getSsrPoweredByLink };
