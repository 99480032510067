/**
 * Get the default decision and respect essential group and legitimate interests.
 */ function getDefaultDecision(groups, respectLegitimateInterests) {
    if (respectLegitimateInterests === void 0) respectLegitimateInterests = true;
    const essentialGroupObj = groups.find((param)=>{
        let { isEssential } = param;
        return isEssential;
    });
    // We need always use the current essential group
    const result = {
        [essentialGroupObj.id]: essentialGroupObj.items.map((param)=>{
            let { id } = param;
            return id;
        })
    };
    if (respectLegitimateInterests) {
        for (const group of groups){
            if (group === essentialGroupObj) {
                continue;
            }
            const ids = group.items.filter((param)=>{
                let { legalBasis } = param;
                return legalBasis === "legitimate-interest";
            }).map((param)=>{
                let { id } = param;
                return id;
            });
            if (ids.length) {
                result[group.id] = ids;
            }
        }
    }
    return result;
}
export { getDefaultDecision };
