import { regExpPatternFromWildcardName } from "..";
/**
 * Synchronously check if a content is blocked and returns the found content blocker.
 * See `window.consentApi.unblock` for more documentation.
 */ function unblockSync(blocker, url) {
    if (!url) {
        return undefined;
    }
    // Find matching blocker
    let found;
    loop1: for (const row of blocker){
        const { rules } = row;
        // Check if host matches
        for (const rule of rules){
            const regexp = regExpPatternFromWildcardName(rule);
            if (url.match(new RegExp(regexp, "s"))) {
                found = row;
                break loop1;
            }
        }
    }
    return found;
}
export { unblockSync };
