const pool = {};
const recreateFetchPool = {};
async function nonceDeprecationPool(nonce, restRecreateNonceEndpoint) {
    if (typeof restRecreateNonceEndpoint !== "undefined") {
        const resultPromise = // Reuse already sent request
        recreateFetchPool[nonce] || new Promise(// eslint-disable-next-line no-async-promise-executor
        async (resolve, reject)=>{
            try {
                const recreateFetch = await window.fetch(restRecreateNonceEndpoint, {
                    method: "POST"
                });
                if (recreateFetch.ok) {
                    const newNonce = await recreateFetch.text();
                    if (nonce === newNonce) {
                        reject();
                    } else {
                        pool[nonce] = newNonce;
                        resolve(newNonce);
                    }
                } else {
                    reject();
                }
            } catch (e) {
                reject();
            }
        });
        recreateFetchPool[nonce] = resultPromise;
        // Remove from pool when request is done
        resultPromise.finally(()=>{
            delete recreateFetchPool[nonce];
        });
        return resultPromise;
    } else {
        if (typeof nonce === "undefined") {
            return undefined;
        }
        // Wait until all nonces are refreshed
        await Promise.all(Object.values(recreateFetchPool));
        let returnNonce = nonce;
        while(pool[returnNonce]){
            returnNonce = pool[returnNonce];
            if (pool[returnNonce] === nonce) {
                break;
            }
        }
        return Promise.resolve(returnNonce);
    }
}
export { nonceDeprecationPool };
