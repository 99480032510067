import { CookieConsentManager } from "../apply";
import { BANNER_PRE_DECISION_SHOW_EVENT, OPT_IN_ALL_EVENT } from "../events";
import { getUserDecision } from ".";
/**
 * Listen to opt-in so we can safely print the UUID of the current consent to HTML elements.
 */ function printConsentUuidsToHTMLElement(selector, decisionCookieName) {
    const elements = Array.prototype.slice.call(document.querySelectorAll(selector));
    document.addEventListener(BANNER_PRE_DECISION_SHOW_EVENT, ()=>{
        elements.forEach((element)=>element.innerHTML = element.getAttribute("data-fallback"));
    });
    document.addEventListener(OPT_IN_ALL_EVENT, ()=>{
        const consent = getUserDecision(decisionCookieName instanceof CookieConsentManager ? decisionCookieName.getOption("decisionCookieName") : decisionCookieName);
        const allUuids = consent ? [
            consent.uuid,
            ...consent.previousUuids
        ] : [];
        elements.forEach((element)=>element.innerHTML = allUuids.length > 0 ? allUuids.join(", ") : element.getAttribute("data-fallback"));
    });
}
export { printConsentUuidsToHTMLElement };
