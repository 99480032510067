import Cookies from "js-cookie";
import { isGdprScanner } from ".";
let memoizeSupported = undefined;
/**
 * Check if the current environment supports cookies.
 *
 * @see https://git.io/JcXiP
 * @returns
 */ function supportsCookies(cookieName) {
    if (cookieName === void 0) cookieName = "test";
    if (typeof memoizeSupported === "boolean") {
        return memoizeSupported;
    }
    // Special case: for GDPR scanners we consider automatically that the browser
    // supports settings cookies.
    if (isGdprScanner()) {
        return true;
    }
    try {
        const opts = {
            sameSite: "Lax"
        };
        Cookies.set(cookieName, "1", opts);
        const result = document.cookie.indexOf(`${cookieName}=`) !== -1;
        Cookies.remove(cookieName, opts);
        memoizeSupported = result;
        return result;
    } catch (e) {
        return false;
    }
}
export { supportsCookies };
