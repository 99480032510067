/**
 * Wait for a given object which is calculated through a callback.
 */ async function waitObject(getObject, delayNextCheck, maxTries) {
    if (delayNextCheck === void 0) delayNextCheck = 500;
    if (maxTries === void 0) maxTries = 0;
    let i = 0;
    while(!getObject()){
        if (maxTries > 0 && i >= maxTries) {
            return undefined;
        }
        await new Promise((r)=>setTimeout(r, delayNextCheck));
        i++;
    }
    return getObject();
}
export { waitObject };
