/**
 * This obfuscate function is used to generate a simple encrypted string from a text and secret. This is very
 * simple so it can be used in frontend (e.g. URL obfuscating). This is not a real encryption as it uses
 * the Vignere Cipher implementation.
 *
 * @see https://www.programmingalgorithms.com/algorithm/vigenere-cipher/php/
 */ function simpleObfuscate(input, key, encipher) {
    const keyLen = key.length;
    // Check if the key contains only alphanumeric characters
    if (!/^[a-z0-9]+$/i.test(key)) {
        return "";
    }
    let output = "";
    let nonAlphaCharCount = 0;
    const inputLen = input.length;
    for(let i = 0; i < inputLen; i++){
        if (/[a-z]/i.test(input[i])) {
            const cIsUpper = input[i] === input[i].toUpperCase();
            const offset = cIsUpper ? "A".charCodeAt(0) : "a".charCodeAt(0);
            const keyIndex = (i - nonAlphaCharCount) % keyLen;
            const keyChar = key[keyIndex];
            let k;
            if (!isNaN(parseInt(keyChar, 10))) {
                k = parseInt(keyChar, 10);
            } else {
                k = (keyChar.toLowerCase().charCodeAt(0) - offset) % 26;
            }
            k = encipher ? k : -k;
            const ch = String.fromCharCode(((input.charCodeAt(i) + k - offset) % 26 + 26) % 26 + offset);
            output += ch;
        } else {
            output += input[i];
            nonAlphaCharCount++;
        }
    }
    return output;
}
export { simpleObfuscate };
